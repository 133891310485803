var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center introBG",attrs:{"lg":"8"}},[_c('video',{staticClass:"newIntro",attrs:{"autoplay":"","loop":""}},[_c('source',{attrs:{"src":"https://assets.brimecdn.com/intro-loop.mp4","type":"video/mp4"}})])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('img',{staticClass:"mb-2",attrs:{"src":"https://assets.brimecdn.com/brime_logo_tailwind.png","width":"100px"}}),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Come explore the future of live with us :) ")]),_c('validation-observer',{ref:"registerForm"},[_c('validation-provider',{attrs:{"name":"Global","vid":"global"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticStyle:{"color":"#e43a3b","font-weight":"bold"}},[_vm._v(" "+_vm._s(errors[0])),_c('br')]),_c('br'),(_vm.securityMessage)?_c('span',{staticStyle:{"color":"#e43a3b","font-weight":"bold"}},[_c('a',{attrs:{"href":"https://support.brime.tv/hc/en-us/requests/new","target":"_blank"}},[_vm._v("Open a Support Ticket")])]):_vm._e()]}}])}),_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"doge","maxlength":"25","minlength":"4"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"doge@brimelive.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············","minlength":"8"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1","required":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"href":"https://support.brimelive.com/hc/en-us/articles/360054884471-Terms-of-Service","target":"_blank"}},[_vm._v(" privacy policy & terms ")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.invalid}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already a Brimer?")]),_c('b-link',{on:{"click":function($event){return _vm.$auth.loginWithRedirect();}}},[_c('span',[_vm._v(" Sign in instead")])])],1),_c('div',{staticStyle:{"display":"block","position":"fixed","bottom":"30px"}},[_vm._v(" This site is protected by reCAPTCHA Enterprise and Google"),_c('br'),_c('a',{attrs:{"href":"https://support.brime.tv/hc/en-us/articles/360054441412-Privacy-Policy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://support.brime.tv/hc/en-us/articles/360054884471-Terms-of-Service","target":"_blank"}},[_vm._v("Terms of Service")]),_vm._v(" apply. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }